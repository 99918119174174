import React, { useState, useEffect, useRef } from "react"
import Img from "gatsby-image"

const Checkbox = props => {
  const { option, checked } = props
  return (
    <div className="list-group-item list-group-item__form-product py-1">
      <div className="custom-control custom-checkbox">

        <input
          type="checkbox"
          className="custom-control-input"
          id={`check-${option.databaseId}`}
          value={`${option.title}`}
          onChange={props.onCheckboxChange}
          checked={props.checked}
        />
       
        <label
          className="custom-control-label row no-gutters align-items-center justify-content-between"
          htmlFor={`check-${option.databaseId}`}
        >
          <div className="px-3 col">{option.title}</div>
          <div style={{ width: 50, height: 50, overflow: 'hidden' }} className="border rounded-circle p-1 col-auto">
          <Img
            fluid={option.featuredImage.node.imageFile.childImageSharp.fluid}
            loading="eager"
          />
          </div>
        </label>
      </div>
    </div>
  )
}

export default Checkbox
