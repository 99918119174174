import React, { useEffect, useRef } from "react"

const SelectField = props => {
  const selectRef = useRef()
  useEffect(() => {
    if (props.labelAboveField) return;
    const { current } = selectRef
    const handleFocus = () => {
      current.classList.add("form-control__focused")
    }
    const handleBlur = () => {
      if (current.value.length === 0) {
        current.classList.remove("form-control__focused")
      }
    }

    if (props.value) {
      handleFocus()
    }

    current.addEventListener("focus", handleFocus)
    current.addEventListener("blur", handleBlur)

    return () => {
      // Cleanup
      current.removeEventListener("focus", handleFocus)
      current.removeEventListener("blur", handleBlur)
    }
  }, [props.value])

  return (
    <div className={`form-group form-group__custom`}>
      {props.labelAboveField && (
        <label htmlFor={props.name} className="mb-2 font-weight-bold text-secondary">{props.label}</label>
      )}
      <select
        ref={selectRef}
        id={props.name}
        name={props.name}
        className="form-control"
        required={props.required}
        onChange={props.onSelectChange}
        defaultValue={props.value}
        {...props}
      >
        {props.children}
      </select>
      {!props.labelAboveField && (
        <label htmlFor={props.name}>{props.label}</label>
      )}
    </div>
  )
}

export default SelectField
